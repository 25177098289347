import NotFound from '@app/components/NotFound/NotFound'
import MySuspense from '@app/routes/MySuspense'
import RouterChange from '@app/routes/RouterChange'
import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { routeConfig } from './routeConfig'

const publicRoutes: JSX.Element[] = []
Object.keys(routeConfig).forEach((key) => {
  const { path, exact, component, title } = routeConfig[key]
  publicRoutes.push(
    <Route
      title={title}
      key={key}
      path={path}
      component={component}
      exact={exact}
    />
  )
})

const RouterContainer: FC = () => {
  return (
    <MySuspense>
      <BrowserRouter>
        <RouterChange>
          <Switch>
            {publicRoutes}
            <Route path='*' component={NotFound} />
          </Switch>
        </RouterChange>
      </BrowserRouter>
    </MySuspense>
  )
}

export default RouterContainer
