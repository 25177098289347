import './ProgressSpinner.css'

import React, { FC } from 'react'

const ProgressSpinner: FC<any> = () => (
  <div
    style={{
      position: 'fixed',
      zIndex: 1000,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <div className='loader' />
  </div>
)

export default ProgressSpinner
