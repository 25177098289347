import { FC, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import React from 'react'

const usePageView = () => {
  const location = useLocation()
  useEffect(() => {
    if (window._env_.IS_PRODUCTION) {
      ReactGA.pageview(location.pathname)
    }
  }, [location])
}

const RouterChange: FC = ({ children }) => {
  usePageView()

  return <React.Fragment>{children}</React.Fragment>
}

export default RouterChange
