import { ApolloProvider } from '@apollo/react-hooks'
import { logger, NETWORK_ERROR } from '@app/libs'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import React, { createContext, FC, useContext, useState } from 'react'

interface MyApolloValue {
  restartClient: () => void
}

const MyApolloContext = createContext<MyApolloValue>({
  restartClient: () => {
    // Empty function
  },
})

let isRefreshing = false

const MyApolloProvider: FC = ({ children }) => {
  const [, setState] = useState(false)
  const HTTP_ENDPOINT = `${window._env_.REACT_APP_ENDPOINT}/graphql`
  const WS_ENDPOINT = `${window._env_.REACT_SUBSCRIPTION_ENDPOINT}/graphql`
  const httpLink = createUploadLink({
    uri: HTTP_ENDPOINT,
  })
  const errorLink = onError(
    ({ operation: { operationName }, graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, extensions }) => {
          if (extensions?.code === 'EXPIRED' && !isRefreshing) {
            isRefreshing = true
            window.location.reload()
          }
          logger.push({
            e: operationName,
            eM: message,
          })
        })
      if (networkError)
        logger.push({
          e: NETWORK_ERROR,
        })
    }
  )
  const wsLink = new WebSocketLink({
    uri: WS_ENDPOINT,
  })
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )
  const cache = new InMemoryCache({
    dataIdFromObject: (object) => object.id,
  })
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, link]),
    cache,
  })

  return (
    <MyApolloContext.Provider
      value={{ restartClient: () => setState((prevState) => !prevState) }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </MyApolloContext.Provider>
  )
}

export const useMyApolloContext = (): MyApolloValue =>
  useContext(MyApolloContext)

export default MyApolloProvider
