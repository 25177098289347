import { onResize } from '@app/libs'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useReducer,
} from 'react'

import {
  initialState,
  MyState,
  MyActionTypes,
  reducers,
} from './context/reducers'

type MyContextData = [MyState, React.Dispatch<MyActionTypes>]

const CustomContext = createContext<MyContextData>([initialState, () => ({})])

export const CustomProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
  }, [])

  if (state.isRefreshing) return <div />

  return (
    <CustomContext.Provider value={[state, dispatch]}>
      {children}
    </CustomContext.Provider>
  )
}

export const CustomConsumer = CustomContext.Consumer

export const useCustom = (): MyContextData => useContext(CustomContext)
