import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import '@app/styles/App.less'
import 'react-router-modal/css/react-router-modal.css'
import 'moment/locale/ja'
import '@app/translation'
import 'react-quill/dist/quill.snow.css'

import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import App from '@app/App'

if (window._env_.IS_PRODUCTION) {
  ReactGA.initialize('UA-176852914-2')
}

// import * as serviceWorker from './serviceWorker'

moment.updateLocale('ja', {
  week: {
    dow: 1,
  },
})
moment.tz.setDefault('Asia/Tokyo')

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
