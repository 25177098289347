import './ErrorComponent.css'

import Icon, { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons'
import { ErrorIcon } from '@app/assets/icons'
import Button from '@app/components/Button/Button'
import i18n from 'i18next'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ApolloError } from 'apollo-client'
import { useCustom } from '@app/hooks'
import { actions } from '@app/provider/context/actions'

const ErrorSubComponent = ({
  onClick,
  message,
  icon,
  buttonText,
  fullHeight,
}) => (
  <div
    className={`sacircle-error-container ${fullHeight ? 'full-height' : ''}`}
  >
    <div className='sacircle-error-upper'>
      <Icon style={{ fontSize: '2em' }} component={ErrorIcon} />
      {i18n.t('main.error')}
    </div>
    <div className='sacircle-error-message'>{message}</div>
    <Button onClick={onClick} icon={icon} data-testid='error'>
      {buttonText}
    </Button>
  </div>
)

const ErrorComponent: FC<{ error?: ApolloError; fullHeight?: boolean }> = ({
  error: { networkError, graphQLErrors } = {
    networkError: undefined,
    graphQLErrors: undefined,
  },
  fullHeight,
}) => {
  const [, dispatch] = useCustom()
  const history = useHistory()

  const onRefresh = () => {
    dispatch({
      type: actions.START_REFRESHING,
    })
    setTimeout(() => {
      dispatch({
        type: actions.FINISH_REFRESHING,
      })
    }, 1000)
  }

  if (networkError)
    return (
      <ErrorSubComponent
        onClick={onRefresh}
        message={i18n.t('main.errorMessage')}
        icon={<ReloadOutlined />}
        buttonText={i18n.t('main.refresh')}
        fullHeight={fullHeight}
      />
    )
  if (graphQLErrors) {
    return (
      <ErrorSubComponent
        onClick={history.goBack}
        message={graphQLErrors?.map(({ message }, index) => (
          <div key={index} className='sacircle-error-message'>
            {message}
          </div>
        ))}
        icon={<ArrowLeftOutlined />}
        buttonText={i18n.t('main.goBack')}
        fullHeight={fullHeight}
      />
    )
  }

  return (
    <ErrorSubComponent
      onClick={() => history.go(0)}
      message={i18n.t('main.errorMessage')}
      icon={<ReloadOutlined />}
      buttonText={i18n.t('main.refresh')}
      fullHeight={fullHeight}
    />
  )
}

export default ErrorComponent
