import MyApolloProvider from '@app/provider/MyApolloProvider'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ConfigProvider } from 'antd'
import jaJP from 'antd/lib/locale/ja_JP'
import React, { FC } from 'react'

import { CustomProvider } from './CustomProvider'

const stripePromise = loadStripe(
  window._env_.REACT_APP_PRISMA_STRIPE_PUBLIC_KEY
)

const Provider: FC = ({ children }) => (
  <ConfigProvider locale={jaJP}>
    <Elements stripe={stripePromise}>
      <CustomProvider>
        <MyApolloProvider>{children}</MyApolloProvider>
      </CustomProvider>
    </Elements>
  </ConfigProvider>
)

export default Provider
