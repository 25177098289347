import { actions } from './actions'

export interface MyState {
  isRefreshing: boolean
}

export interface MyActionTypes {
  type: actions
  payload?: any
}

export const initialState: MyState = {
  isRefreshing: false,
}

export const reducers = (state: MyState, action: MyActionTypes): MyState => {
  switch (action.type) {
    case actions.START_REFRESHING: {
      return {
        isRefreshing: true,
      }
    }
    case actions.FINISH_REFRESHING: {
      return {
        isRefreshing: false,
      }
    }

    default:
      throw new Error()
  }
}
