/* eslint-disable no-unused-vars */
import moment from 'moment'

export const LANGUAGE_KEY = 'language'
export const COOKIE_KEY = 'authorization'
export const EMPLOYEE_TOKEN_KEY = 'employee_token'
export const STAFF_TOKEN_KEY = 'staff_token'
export const ADMIN_TOKEN_KEY = 'admin_token'
export const CART_KEY = 'cart_schedules'
export const ALL_SIZE = 'all_size'
export const COMPANY_KEY = 'company_key'

export const MAX_INPUT_LENGTH = 250
export const TITLE_MAX_INPUT_LENGTH = 101
export const TEXT_AREA_MAX_INPUT_LENGTH = 500
export const INPUT_NUMBER_MAX_LENGTH = 10000
export const INPUT_NUMBER_MIN_LENGTH = 0
export const DATE_PICKER_MIN_DATE = '1970-01-01T00:00:00.000Z'
export const DATE_PICKER_MAX_DATE = moment().endOf('year').add(10, 'year')

// Max file upload size 5 MB
export const MAX_FILE_SIZE = 5
export const MAX_NUMBER_OF_FILES = 10
export const NO_BACKEND_INTEGRATION =
  'このページはまだバックエンドと連携していません'

export enum USER_ACTIVE_STATUS {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ON_LEAVE = 'ON_LEAVE',
}

export const LAST_BUTTON_DATE_KEY = 'lastButtonDate'
