import { routes } from './routes'

export const Router = {
  ...routes,
  get: (
    pathInput: string,
    params?: {
      [key: string]: string | undefined
    }
  ): string => {
    let path = pathInput
    if (params) {
      Object.keys(params).forEach((paramKey) => {
        path = path.replace(`:${paramKey}`, params[paramKey] || '')
      })
    }
    return path
  },
}
