const getFirstChildKey = ({ groups, i = 0 }) => {
  let j = i
  const key = Object.keys(groups)[j]
  if (groups[key].hide) {
    j = i + 1
    return getFirstChildKey({ groups, i: j })
  }
  return key
}

export const getClosestPath = ({ groups, key, path = '' }) => {
  const concatenatedPath = `${path}${groups[key].path}`
  if (groups[key].children) {
    const itemGroups = groups[key].children
    if (
      !Object.keys(itemGroups).reduce((acc, item) => {
        if (!itemGroups[item].hide) {
          acc += 1
        }
        return acc
      }, 0)
    ) {
      return concatenatedPath
    }
    const firstChildKey = getFirstChildKey({ groups: groups[key].children })
    return getClosestPath({
      groups: groups[key].children,
      key: firstChildKey,
      path: concatenatedPath,
    })
  }
  return concatenatedPath
}
