import ProgressSpinner from '@app/components/ProgressSpinner/ProgressSpinner'
import ErrorComponent from '@app/components/ErrorComponent/ErrorComponent'
import React, { Suspense } from 'react'
import { logger } from '@app/libs'

interface MySuspenseState {
  hasError: boolean
}

class MySuspense extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(): MySuspenseState {
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    logger.push({
      error,
      errorInfo,
    })
  }

  render(): JSX.Element {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      return <ErrorComponent />
    }

    return <Suspense fallback={<ProgressSpinner />}>{children}</Suspense>
  }
}
export default MySuspense
