import { Logger } from 'cloudwatch-front-logger'

const accessKeyId = window._env_.AWS_ACCESS_KEY

const secretAccessKey = window._env_.AWS_ACCESS_SECRET_KEY
const region = 'ap-northeast-1'

const logGroupName = window._env_.LOG_GROUP_NAME || 'goldware_dev'

const awsLogger = new Logger(accessKeyId, secretAccessKey, region, logGroupName)
awsLogger.install({
  logStreamNameResolver() {
    return 'front-end'
  },
  async messageFormatter(e, info = { type: 'unknown' }) {
    if (!e.message) {
      return null
    }
    return JSON.stringify({
      message: e.message,
      timestamp: new Date().getTime(),
      userAgent: window.navigator.userAgent,
      ...info,
    })
  },
})

export const logger = {
  push: window._env_.IS_PRODUCTION
    ? (parameter) => {
        if (parameter?.info !== 'ONLINE') {
          awsLogger.onError({ message: 'REQUEST' }, parameter)
        }
      }
    : (parameter) => parameter,
}
