import React, { FC } from 'react'
import RouterContainer from '@app/routes/RouterContainer'
import Provider from '@app/provider/index'

const App: FC = () => (
  <Provider>
    <RouterContainer />
  </Provider>
)

export default App
