export enum actions {
  INIT = 'INIT',
  SET_TOKEN = 'SET_TOKEN',
  SET_COLLAPSED = 'SET_COLLAPSED',
  SET_ROUTE = 'SET_ROUTE',
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
  ADD_SCHEDULE = 'ADD_SCHEDULE',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  REMOVE_SCHEDULE = 'REMOVE_SCHEDULE',
  REMOVE_ALL_SCHEDULE = 'REMOVE_ALL_SCHEDULE',
  LOADING_START = 'LOADING_START',
  LOADING_FINISH = 'LOADING_FINISH',
  SET_ERROR = 'SET_ERROR',
  SET_MASTER_DATA = 'SET_MASTER_DATA',
  CHANGE_TABLE_SIZE = 'CHANGE_TABLE_SIZE',
  OPEN_GUIDE = 'OPEN_GUIDE',
  CLOSE_GUIDE = 'CLOSE_GUIDE',
  TOGGLE_HELP_DRAWER = 'TOGGLE_HELP_DRAWER',
  SHOW_HELP_GUIDE = 'SHOW_HELP_GUIDE',
  SHOW_SALESCIRCLE_NEWS = 'SHOW_SALESCIRCLE_NEWS',
  HIDE_SALESCIRCLE_NEWS = 'HIDE_SALESCIRCLE_NEWS',
  UPDATE_EMPLOYEE_ME = 'UPDATE_EMPLOYEE_ME',
  START_REFRESHING = 'START_REFRESHING',
  FINISH_REFRESHING = 'FINISH_REFRESHING',
}
