import { Router } from '@app/routes/router'
import { lazy } from 'react'
import i18n from 'i18next'
import { RouteMainConfig } from '../models/route.model'

const UpdatePayment = lazy(
  () => import('@app/containers/shared/UpdatePayment/UpdatePayment')
)
const Registration = lazy(
  () => import('@app/containers/shared/Registration/Registration')
)

const TermsOfService = lazy(
  () => import('@app/containers/shared/TermsOfService/TermsOfService')
)
const PrivacyPolicy = lazy(
  () => import('@app/containers/shared/PrivacyPolicy/PrivacyPolicy')
)
const SpecifiedCommercialTransaction = lazy(
  () =>
    import(
      '@app/containers/shared/SpecifiedCommercialTransaction/SpecifiedCommercialTransaction'
    )
)
const PersonalInfo = lazy(
  () => import('@app/containers/shared/PersonalInfo/PersonalInfo')
)

export const routeConfig: RouteMainConfig = {
  Registration: {
    path: Router.Registration,
    component: Registration,
    title: i18n.t('title.registration'),
  },
  UpdatePayment: {
    path: Router.UpdatePayment,
    component: UpdatePayment,
    title: i18n.t('title.updatePayment'),
  },
  TermsOfService: {
    exact: true,
    path: Router.TermsOfService,
    component: TermsOfService,
    title: i18n.t('title.termsOfService'),
  },
  PrivacyPolicy: {
    exact: true,
    path: Router.PrivacyPolicy,
    component: PrivacyPolicy,
    title: i18n.t('title.privacyPolicy'),
  },
  SpecifiedCommercialTransaction: {
    exact: true,
    path: Router.SpecifiedCommercialTransaction,
    component: SpecifiedCommercialTransaction,
    title: i18n.t('title.sct'),
  },
  PersonalInfo: {
    exact: true,
    path: Router.PersonalInfo,
    component: PersonalInfo,
    title: i18n.t('title.personalInfo'),
  },
}
